import { sendEventToFb } from "./facebook"

const sendEventToTg = (params, fbEvent) => {
  const http = new XMLHttpRequest()
  const url = "/post_event_to_tg.php"

  return new Promise(resolve => {
    http.open("POST", url, true)

    http.setRequestHeader("Content-Type", "application/x-www-form-urlencoded")

    http.onreadystatechange = function () {
      if (http.readyState === 4) {
        http.status === 200 ? resolve(true) : resolve(false)
      }
    }

    http.send(
      Object.keys(params)
        .map(key => `${key}=${encodeURIComponent(params[key]) || ""}`)
        .join("&")
    )

    sendEventToFb(fbEvent)
  })
}

export const notifyPhoneClick = ({ category, label }) => {
  const params = {
    title: "Клік по телефону👇📲",
    subtitle: "Можуть зараз зателефонувати!!!!",
    category,
    label,
    url: window.location.href,
  }

  return sendEventToTg(params, "Phone Click")
}

export const notifyMessengerClick = ({ category, label, messenger }) => {
  const params = {
    title: `Клік написати в ${messenger}😎📩`,
    subtitle: `Можуть написати в ${messenger}!!!!`,
    category,
    label,
    url: window.location.href,
  }

  return sendEventToTg(params, "Phone Click")
}
