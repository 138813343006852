import React, { useState, useCallback } from "react"
import Modal from "react-overlays/Modal"

import { HourGlassLoader } from "../loader"
import {
  getIcon,
  notifyPhoneClick,
  sendEventToGA4,
  sendLead,
  wait,
} from "../../utils/index"
import { Icon } from "../../components/icon"
import { SuccessForm } from "../modal"

import styles from "./callback.module.scss"
import { useSiteMetadata } from "../../hooks"

function CallbackForm({ setShowCallbackForm, showCallbackForm }) {
  const renderBackdrop = props => <div className="modal-backdrop" {...props} />

  const [showSuccess, setShowSuccess] = useState(false)

  const title = "Зворотній дзвінок"

  const [result, setResult] = useState()
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState(
    "Будь-ласка, зателефонуйте мені найближчим часом."
  )

  const [isSending, setIsSending] = useState(false)

  const sendRequest = useCallback(
    async params => {
      if (isSending) {
        return
      }

      setIsSending(true)

      const result = await sendLead({
        name: params.name,
        phone: params.phone,
        message: params.message,
        form: params.title,
      })

      await wait(1.5)

      setResult(result)

      if (result) {
        setShowCallbackForm(false)
        setShowSuccess(true)

        sendEventToGA4({
          action: "send_form",
          category: "form",
          label: "Order Call Back Form",
        })
      }

      setIsSending(false)
    },
    [isSending, setShowCallbackForm]
  )

  const sendForm = () =>
    phone ? sendRequest({ name, phone, message, title }) : setResult(false)

  const {
    contacts: { phone: fullPhone, formattedPhone },
  } = useSiteMetadata()

  const handlePhoneClick = () => {
    sendEventToGA4({
      action: "phone_click",
      category: "phone",
      label: "Order Call Back Form",
    })

    notifyPhoneClick({
      category: "Форма",
      label: "Зворотній дзвінок - знизу",
    })
  }

  return (
    <div>
      <Modal
        show={showCallbackForm}
        onHide={() => setShowCallbackForm(false)}
        aria-labelledby="modal-label"
        renderBackdrop={renderBackdrop}
        className={styles.modal}
      >
        <div className={styles.formWrapper}>
          <div className={styles.close}>
            <h3>{title}</h3>
            <button
              onClick={() => setShowCallbackForm(false)}
              type="button"
              aria-label="закрити"
            >
              <Icon name={"закрити"} icon={getIcon("close")} />
            </button>
          </div>
          <form className={styles.form}>
            <input
              placeholder="Ваше ім'я"
              type="text"
              name="name"
              value={name}
              onChange={e => setName(e.target.value)}
            />
            <input
              placeholder="Ваш телефон"
              type="tel"
              name="phone"
              value={phone}
              onChange={e => setPhone(e.target.value)}
            />
            <textarea
              placeholder="Питання"
              value={message}
              rows="3"
              onChange={e => setMessage(e.target.value)}
            />
            <div className={styles.sendResultWrapper}>
              {[true, false].includes(result) ? (
                <span>
                  {result === false &&
                    "Помилка! Перевірте дані та спробуйте ще раз."}
                </span>
              ) : null}
            </div>

            <div className={styles.sendButtonWrapper}>
              {isSending ? (
                <HourGlassLoader />
              ) : (
                <button
                  className={styles.primaryBtn}
                  type="button"
                  onClick={sendForm}
                >
                  Відправити
                </button>
              )}
            </div>

            <a
              href={`tel:${fullPhone}`}
              className={styles.phoneLink}
              onClick={handlePhoneClick}
            >
              <div className={styles.phone}>
                <Icon name={"зателефонувати"} icon={getIcon("touch app")} />
                <span>{formattedPhone}</span>
              </div>
              <span>зателефонувати самостійно</span>
            </a>
          </form>
        </div>
      </Modal>
      <SuccessForm show={showSuccess} setShow={setShowSuccess} name={name} />
    </div>
  )
}

export default CallbackForm
