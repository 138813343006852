import React, { useCallback, useState } from "react"

import Modal from "react-overlays/Modal"

import Img from "gatsby-image/index"

import { getIcon, sendEventToGA4, sendLead, wait } from "../../utils/index"
import { notifyPhoneClick } from "../../utils/index"
import { Icon } from "../../components/icon"

import styles from "./interesting-house.module.scss"
import { SuccessForm } from "../modal"
import { HourGlassLoader } from "../loader"
import { useSiteMetadata } from "../../hooks"

function InterestingHouseForm({ title, thumbnail, showButtonTitle }) {
  const [show, setShow] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const renderBackdrop = props => <div className="modal-backdrop" {...props} />

  const [result, setResult] = useState()
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState(
    `Хочу отримати більш детальну та свіжу інформацію про '${title}'`
  )

  const [isSending, setIsSending] = useState(false)

  const sendRequest = useCallback(
    async params => {
      if (isSending) {
        return
      }

      setIsSending(true)

      const result = await sendLead({
        name: params.name,
        phone: params.phone,
        message: params.message,
        form: params.title,
        image: params.image ? "https:" + params.image : null,
      })

      await wait(1.5)

      setResult(result)

      if (result) {
        setShow(false)
        setShowSuccess(true)

        sendEventToGA4({
          action: "send_form",
          category: "form",
          label: "Interesting House Form",
        })
      }

      setIsSending(false)
    },
    [isSending]
  )

  const sendForm = () =>
    phone
      ? sendRequest({
          name,
          phone,
          message,
          title,
        })
      : setResult(false)

  const {
    contacts: { phone: fullPhone, formattedPhone },
  } = useSiteMetadata()

  const handlePhoneClick = () => {
    sendEventToGA4({
      action: "phone_click",
      category: "phone",
      label: "Interesting House Form",
    })

    notifyPhoneClick({
      category: "Форма",
      label: "Мене цікавить будинок - знизу",
    })
  }

  return (
    <div className={styles.formInvoker}>
      <button onClick={() => setShow(true)} type="button">
        {showButtonTitle || "Цікаво"}
      </button>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="modal-label"
        renderBackdrop={renderBackdrop}
        className={styles.modal}
      >
        <div className={styles.formWrapper}>
          <div className={styles.close}>
            <h3>Дізнайтеся більше про цей об'єкт</h3>
            <button
              onClick={() => setShow(false)}
              type="button"
              aria-label="закрити"
            >
              <Icon name={"закрити"} icon={getIcon("close")} />
            </button>
          </div>
          <Img
            fluid={thumbnail}
            objectFit="cover"
            objectPosition="50% 50%"
            className={styles.thumbnail}
            alt={title}
          />
          <form className={styles.form}>
            <input
              placeholder="Ваше ім'я"
              type="text"
              name="name"
              value={name}
              onChange={e => setName(e.target.value)}
            />
            <input
              placeholder="Ваш телефон"
              type="tel"
              name="phone"
              value={phone}
              onChange={e => setPhone(e.target.value)}
            />
            <textarea
              placeholder="Питання"
              value={message}
              onChange={e => setMessage(e.target.value)}
              rows="3"
            />
            <div className={styles.sendResultWrapper}>
              {[true, false].includes(result) ? (
                <span>
                  {result === false &&
                    "Помилка! Перевірте дані та спробуйте ще раз."}
                </span>
              ) : null}
            </div>

            <div className={styles.sendButtonWrapper}>
              {isSending ? (
                <HourGlassLoader />
              ) : (
                <button
                  className={styles.primaryBtn}
                  type="button"
                  onClick={sendForm}
                >
                  Відправити
                </button>
              )}
            </div>

            <a
              href={`tel:${fullPhone}`}
              className={styles.phoneLink}
              onClick={handlePhoneClick}
            >
              <div className={styles.phone}>
                <Icon name={"зателефонувати"} icon={getIcon("phone link")} />
                <span>{formattedPhone}</span>
              </div>
              <span className={styles.phoneText}>
                зателефонувати самостійно
              </span>
            </a>
          </form>
        </div>
      </Modal>
      <SuccessForm show={showSuccess} setShow={setShowSuccess} name={name} />
    </div>
  )
}

export default InterestingHouseForm
