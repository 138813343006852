import React from "react"
import Modal from "react-overlays/Modal"

import { notifyPhoneClick, sendEventToGA4 } from "../../utils/index"
import { getIcon } from "../../utils/index"
import { Icon } from "../../components/icon"
import { useSiteMetadata } from "../../hooks"
import styles from "./success-model.module.scss"

function SuccessForm({ show, setShow, name }) {
  const renderBackdrop = props => <div className="modal-backdrop" {...props} />
  const title = name ? `Дякуємо, ${name}!` : "Дякуємо!"

  const {
    contacts: { phone: fullPhone, formattedPhone },
  } = useSiteMetadata()

  const handlePhoneClick = () => {
    sendEventToGA4({
      action: "phone_click",
      category: "phone",
      label: "Form Success Modal",
    })

    notifyPhoneClick({
      category: "Подяка",
      label: "Успішно відправлена форма",
    })
  }

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="modal-label"
      renderBackdrop={renderBackdrop}
      className={styles.modal}
    >
      <div>
        <div className={styles.close}>
          <h3>{title}</h3>
          <button
            onClick={() => setShow(false)}
            type="button"
            aria-label="закрити"
          >
            <Icon name={"закрити"} icon={getIcon("close")} />
          </button>
        </div>
        <div className={styles.text}>
          Дані успішно відправлено. Ми будемо намагатися зв'язатися з Вами
          якнайшвидше.
        </div>
        <a
          href={`tel:${fullPhone}`}
          className={styles.phoneLink}
          onClick={handlePhoneClick}
        >
          <div className={styles.phone}>
            <Icon name={"зателефонувати"} icon={getIcon("phone link")} />
            <span>{formattedPhone}</span>
          </div>
          <span className={styles.phoneText}>зателефонувати самостійно</span>
        </a>
      </div>
    </Modal>
  )
}

export default SuccessForm
