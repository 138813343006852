import React, { useState } from "react"
import { Link } from "gatsby"

import { getIcon } from "../../../utils/index"
import { Icon } from "../../icon"

import MobileModalWindow from "./mobile-modal-window"
import { CallbackForm } from "../../form"

import styles from "./menu.module.scss"

import logoDesktop from "../../../../static/ready-house.svg"
import logoMobile from "../../../../static/ready-house-mobile.png"
import { useSiteMetadata } from "../../../hooks"

const HeaderMenu = ({ menu }) => {
  const [showMobile, setShowMobile] = useState(false)
  const [showCallbackForm, setShowCallbackForm] = useState(false)
  const {
    contacts: { formattedPhone },
  } = useSiteMetadata()

  return (
    <nav className={styles.menu}>
      <div className={styles.mobileMenu}>
        <Link to="/" className={styles.logoMobile}>
          <img
            src={logoMobile}
            alt="Готові будинки в м. Київ та Київській області"
          />
        </Link>
        <div className={styles.callbackMobile}>
          <button onClick={() => setShowCallbackForm(true)}>
            <span>{formattedPhone}</span>
          </button>
        </div>
        <button
          onClick={() => setShowMobile(true)}
          className={styles.mobileMenuBar}
        >
          <Icon name="Меню" icon={getIcon("menu")} />
        </button>
      </div>
      <div className={styles.desktopMenu}>
        <Link to="/" className={styles.logoDesktop}>
          <img
            src={logoDesktop}
            alt="Готові будинки в м. Київ та Київській області"
          />
        </Link>
        <ul className={styles.navList}>
          {menu.map(pageInfo => {
            return (
              <li key={pageInfo.path}>
                <Link
                  className={styles.navLink}
                  activeClassName={styles.navLinkActive}
                  to={pageInfo.path}
                >
                  {pageInfo.label}
                </Link>
              </li>
            )
          })}
        </ul>
        <div className={styles.callbackDesktop}>
          <button onClick={() => setShowCallbackForm(true)}>
            <Icon name="Зворотній зв’язок" icon={getIcon("phone")} />
            <span>{formattedPhone}</span>
          </button>
        </div>
      </div>
      <CallbackForm
        setShowCallbackForm={setShowCallbackForm}
        showCallbackForm={showCallbackForm}
      />
      <MobileModalWindow
        menu={menu}
        showMobile={showMobile}
        setShowMobile={setShowMobile}
      />
    </nav>
  )
}

export default HeaderMenu
