export const calcCityHouses = houses => {
  const citiesMap = houses.reduce((map, house) => {
    const citySlug = house.city.slug
    const count = (map[citySlug]?.count || 0) + 1

    map[citySlug] = map[citySlug] || house.city
    map[citySlug].count = count

    if (!house.archived) {
      const minPrice = map[citySlug]?.minPrice || Number.POSITIVE_INFINITY
      const minArea = map[citySlug]?.minArea || Number.POSITIVE_INFINITY
      const maxArea = map[citySlug]?.maxArea || 0

      map[citySlug].minPrice = Math.min(minPrice, house.price || minPrice)
      map[citySlug].minArea = Math.min(minArea, house.area || minArea)
      map[citySlug].maxArea = Math.max(maxArea, house.area || maxArea)
      map[citySlug].liveCount = (map[citySlug]?.liveCount || 0) + 1
    }

    if (!map[citySlug].thumbnail) {
      map[citySlug].thumbnail = house.thumbnail
    }

    return map
  }, {})

  return Object.values(citiesMap).sort((a, b) => b.liveCount - a.liveCount)
}
