export const getRoomsWord = count => {
  if (count === 1) {
    return "кімната"
  }

  if (count >= 5) {
    return "кімнат"
  }

  return "кімнати"
}

export const getBathroomsWord = count => {
  if (count === 1) {
    return "санвузол"
  }

  if (count >= 5) {
    return "санвузлів"
  }

  return "санвузли"
}

export const getSiteWord = count => {
  if (count < 2) {
    return "сотка"
  }

  if (count >= 2 && count < 5) {
    return "сотки"
  }

  return "соток"
}
