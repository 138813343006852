import { ICONS } from "../constants"

const getIcon = name => {
  let icon

  switch (name) {
    case "twitter":
      icon = ICONS.TWITTER
      break
    case "vkontakte":
      icon = ICONS.VKONTAKTE
      break
    case "telegram":
      icon = ICONS.TELEGRAM
      break
    case "viber":
      icon = ICONS.VIBER
      break
    case "email":
      icon = ICONS.EMAIL
      break
    case "rss":
      icon = ICONS.RSS
      break
    case "instagram":
      icon = ICONS.INSTAGRAM
      break
    case "line":
      icon = ICONS.LINE
      break
    case "facebook":
      icon = ICONS.FACEBOOK
      break
    case "youtube":
      icon = ICONS.YOUTUBE
      break
    case "phone":
      icon = ICONS.PHONE
      break
    case "location":
      icon = ICONS.LOCATION
      break
    case "fire":
      icon = ICONS.FIRE
      break
    case "house":
      icon = ICONS.HOUSE
      break
    case "house two":
      icon = ICONS.HOUSE_TWO
      break
    case "close":
      icon = ICONS.CLOSE
      break
    case "menu":
      icon = ICONS.MENU
      break
    case "folder":
      icon = ICONS.FOLDER
      break
    case "camera":
      icon = ICONS.CAMERA
      break
    case "phone link":
      icon = ICONS.PHONE_LINK
      break
    case "paint":
      icon = ICONS.PAINT
      break
    case "new":
      icon = ICONS.NEW
      break
    case "assigment turned in":
      icon = ICONS.ASSIGNMENT_TURNED_IN
      break
    case "builder":
      icon = ICONS.BUILDER
      break
    case "touch app":
      icon = ICONS.TOUCH_APP
      break
    case "arrow bottom":
      icon = ICONS.ARROW_BOTTOM
      break
    case "account check":
      icon = ICONS.ACCOUNT_CHECK
      break
    case "filter plus outline":
      icon = ICONS.FILTER_PLUS_OUTLINE
      break
    case "cart plus":
      icon = ICONS.CART_PLUS
      break
    case "two man":
      icon = ICONS.TWO_MAN
      break
    case "compass":
      icon = ICONS.COMPASS
      break
    default:
      icon = {}
      break
  }

  return icon
}

export default getIcon
