import React from "react"
import Helmet from "react-helmet"
import SwiperCore, {
  Navigation,
  A11y,
  Keyboard,
  Lazy,
  Pagination,
  Zoom,
} from "swiper"

import Header from "../header/header"
import Footer from "../footer/footer"
import defaultThumb from "../../../static/thumb.png"

import styles from "./layout.module.scss"
import { useSiteMetadata } from "../../hooks"

SwiperCore.use([Navigation, A11y, Keyboard, Lazy, Pagination, Zoom])

const Layout = ({
  children,
  title,
  description,
  thumbnail,
  contentClassName,
  location,
  HeaderBanner,
}) => {
  const { menu, title: configTitle } = useSiteMetadata()

  title =
    (title || "").length > 60
      ? title
      : `${title ? title + " - " : ""}${configTitle}`

  const homeUrl = "https://ready-house.kiev.ua"
  const currentUrl = `${homeUrl}${location.pathname}`

  return (
    <div className={styles.layout}>
      <Helmet>
        <html lang="uk" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:site_name" content={title} />
        <meta property="og:locale" content="ua_UA" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={thumbnail || defaultThumb} />

        <meta itemProp="image" content={thumbnail || defaultThumb} />
        <meta name="robots" content="all" />

        <link href={currentUrl} rel="canonical" />
        <link href={homeUrl} rel="home" />
      </Helmet>

      <Header menu={menu} />
      {HeaderBanner && <HeaderBanner />}
      <section
        className={[contentClassName, styles.content].filter(c => c).join(" ")}
      >
        {children}
      </section>
      <Footer />
    </div>
  )
}

export default Layout
