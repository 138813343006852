import React, { useState } from "react"

import { useSiteMetadata } from "../../hooks"

import {
  getIcon,
  notifyPhoneClick,
  notifyMessengerClick,
  sendEventToGA4,
} from "../../utils/index"
import { Icon } from "../../components/icon"
import { CallbackForm } from "../form"

import styles from "./footer.module.scss"
import { Link } from "gatsby"

const Footer = () => {
  const {
    menu,
    copyright,
    contacts: { phone, instagram, facebook, telegram, viber },
  } = useSiteMetadata()

  const [showCallbackForm, setShowCallbackForm] = useState(false)

  const handlePhoneClick = () => {
    sendEventToGA4({
      action: "phone_click",
      category: "phone",
      label: "Footer Phone",
    })

    notifyPhoneClick({
      category: "Підвал",
      label: "Підвал сайту - іконка",
    })
  }

  const handleMessengerClick = messenger => {
    sendEventToGA4({
      action: "social_click",
      category: "social",
      label: `Footer ${messenger}`,
    })

    notifyMessengerClick({
      messenger,
      category: "Підвал",
      label: "Підвал - іконка",
    })
  }

  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <div className={styles.firstRaw}>
          <div className={styles.icons}>
            <a href={`tel:${phone}`} onClick={handlePhoneClick}>
              <Icon name={"phone"} icon={getIcon("phone")} />
            </a>
            <a
              href={`tg://resolve?domain=${telegram}`}
              onClick={() => handleMessengerClick("Telegram")}
            >
              <Icon name={"telegram"} icon={getIcon("telegram")} />
            </a>
            <a
              href={`viber://chat?number=${viber}`}
              onClick={() => handleMessengerClick("Viber")}
            >
              <Icon name={"viber"} icon={getIcon("viber")} />
            </a>
            <a href={instagram} target="_blank" rel="noreferrer">
              <Icon name={"instagram"} icon={getIcon("instagram")} />
            </a>
            <a href={facebook} target="_blank" rel="noreferrer">
              <Icon name={"facebook"} icon={getIcon("facebook")} />
            </a>
          </div>
          <div className={styles.menu}>
            <ul className={styles.linkList}>
              {menu.map(pageInfo => {
                return (
                  <li key={pageInfo.path}>
                    <Link className={styles.link} to={pageInfo.path}>
                      {pageInfo.label}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
          <button
            className={styles.primaryBtn}
            onClick={() => setShowCallbackForm(true)}
          >
            <Icon name="Зворотній зв’язок" icon={getIcon("phone")} />
            <span>Замовити дзвінок</span>
          </button>
          <CallbackForm
            setShowCallbackForm={setShowCallbackForm}
            showCallbackForm={showCallbackForm}
          />
        </div>

        <p className={styles.copyright}>{copyright}</p>
      </div>
    </footer>
  )
}

export default Footer
