import { useStaticQuery, graphql } from "gatsby"

const useHousesList = limit => {
  limit = limit || 40

  const { allContentfulHouse } = useStaticQuery(
    graphql`
      query {
        allContentfulHouse(
          filter: { node_locale: { eq: "uk-UA" }, archived: { ne: true } }
          sort: { fields: [archived, landArea], order: [ASC, DESC] }
        ) {
          edges {
            node {
              createdAt
              area
              slug
              code
              snippet
              roomsCount
              hotSale
              landArea
              bathrooms_number
              price
              archived
              isFirst
              building
              city {
                name
                slug
                region
                thumbnail {
                  fluid(maxWidth: 600) {
                    sizes
                    src
                    srcSet
                  }
                }
              }
              status {
                name
              }
              location {
                lon
                lat
              }
              thumbnail {
                fluid(maxWidth: 600) {
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
      }
    `
  )

  const houses = allContentfulHouse.edges.map(edge => edge.node)

  return houses
    .sort((a, b) =>
      b.isFirst === a.isFirst || a.archived || b.archived
        ? 0
        : a.isFirst
        ? -1
        : 1
    )
    .slice(0, limit)
}

export default useHousesList
