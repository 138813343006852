import { useStaticQuery, graphql } from "gatsby"

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            url
            title
            slogan
            menu {
              label
              path
              icon
            }
            copyright
            contacts {
              viber
              facebook
              telegram
              telegramChannel
              instagram
              phone
              formattedPhone
              email
              rss
              youtube
              lun
              tiktok
            }
          }
        }
      }
    `
  )

  return site.siteMetadata
}

export default useSiteMetadata
