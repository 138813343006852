import React from "react"
import Modal from "react-overlays/Modal"

import { getIcon } from "../../../utils/index"
import { Icon } from "../../icon"

import styles from "./mobile-modal-window.module.scss"
import { Link } from "gatsby"
import logo from "../../../../static/logo-mini-2.svg"

function MobileModalWindow({ menu, setShowMobile, showMobile }) {
  const renderBackdrop = props => <div className="modal-backdrop" {...props} />

  return (
    <div>
      <Modal
        show={showMobile}
        onHide={() => setShowMobile(false)}
        aria-labelledby="modal-label"
        renderBackdrop={renderBackdrop}
        className={styles.modal}
      >
        <ul className={styles.mobileMenuList}>
          <li>
            <Link
              onClick={() => setShowMobile(false)}
              to="/"
              className={styles.logoLink}
            >
              <img
                src={logo}
                alt="Готові будинки в м. Київ та Київській області"
              />
            </Link>
          </li>
          {menu.map(pageInfo => {
            return (
              <li key={pageInfo.path}>
                <Link onClick={() => setShowMobile(false)} to={pageInfo.path}>
                  <Icon name={pageInfo.icon} icon={getIcon(pageInfo.icon)} />
                  {pageInfo.label}
                </Link>
              </li>
            )
          })}
        </ul>
      </Modal>
    </div>
  )
}

export default MobileModalWindow
