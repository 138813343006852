import React from "react"
import styles from "./icon.module.scss"

const Icon = ({ name, icon }) => (
  <svg className={styles["icon"]} viewBox={icon.viewBox}>
    <title>{name}</title>
    <path d={icon.path} />
    {icon.pathTwo ? <path d={icon.pathTwo} /> : null}
    {icon.pathThree ? <path d={icon.pathThree} /> : null}
    {icon.pathFour ? <path d={icon.pathFour} /> : null}
  </svg>
)

export default Icon
