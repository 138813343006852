import { useStaticQuery, graphql } from "gatsby"
//wip
const useCitiesList = () => {

  const { allContentfulHouse } = useStaticQuery(
    graphql`
      query {
        allContentfulHouse(
          filter: { node_locale: { eq: "uk-UA" } }
          sort: { fields: [archived, landArea], order: [ASC, DESC] }
        ) {
          edges {
            node {
              archived
              city {
                name
                slug
                region
                thumbnail {
                  fluid(maxWidth: 600) {
                    sizes
                    src
                    srcSet
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const cities = allContentfulHouse.edges
    .map(edge => edge.node)
    .reduce((map, house) => {
      const { city, archived } = house

      map[city.slug] = {
        slug: city.slug,
        name: city.name,
        houses: (map[city.slug]?.houses || 0) + (archived ? 0 : 1),
      }
      return map
    }, {})

  return Object.values(cities).sort((a, b) => b.houses - a.houses)
}

export default useCitiesList
