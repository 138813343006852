import React from "react"
import Menu from "./menu"

import styles from "./header.module.scss"

const Header = ({ menu }) => {
  return (
    <header className={styles.header}>
      <Menu menu={menu} />
    </header>
  )
}

export default Header
